import React from "react";
import { CookiesProvider } from "react-cookie";

export const wrapRootElement = ({ element }) => {
  return <CookiesProvider>{element}</CookiesProvider>;
};

export const onClientEntry = () => {
  // Importing CSS files dynamically
  import("./src/styles/global.css");
  import("./public/themeStylesheet.css");
  import("@webdeveducation/wp-block-tools/dist/css/style.css");
};
